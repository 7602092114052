import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonIcon from './IcomoonIcon';
import { breakpoints , color } from '../../utils/style';

const Popup = styled('div')`
  
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 5000;
	display: none;
	overflow-x: hidden;
	overflow-y: scroll;
	background: rgba(0,0,0,0.7);
  
  .popup-overlay {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	
	cursor: pointer;
	display: block;
  }
  
  .popup-content-wrapper {
    width: 580px;
    margin: 50px auto 70px;
    position: relative;
    z-index: 2;
    background: ${color.black};
    border:1px solid ${color.black};
    padding: 30px;
    border-radius: 10px;
  }
  &.wide {
    .popup-content-wrapper {
        width: 1000px;
    }
  }
  &.padded {
    .popup-content-wrapper {
      padding: 50px;
    }
  }
  &.title-orange {
    .h3 {
      color: ${color.orange};
      margin-bottom: 10px;
    }
  }
  
  .h3 {
    margin-top: 0;
  }
  p {
    margin: 10px 0;
  }
  .popup-content {
    overflow-y: auto;
  }
  &.custom-game-popup {
    .h3 {
      max-width: 50%;
      padding-right: 0;
    }
    .popup-content-wrapper {
      //max-height: 100vh;
    }
    .popup-content {
      overflow-y: visible;
    }
  }
  &.popup-open {
    display: block;
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  @media (max-width: ${breakpoints.lg}) {
    &.wide {
      .popup-content-wrapper {
        width: 800px;
      }
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    &.wide {
      .popup-content-wrapper {
        width: 500px;
      }
    }
    &.custom-game-popup {
      //width: 100% !important;
      //top: 0;
      //margin-left: -50% !important;
      padding: 0;
      
      .close-button {
        top: 15px;
        right: 15px;
      }
      .popup-content-wrapper {
        padding: 0;
        width: 100% !important;
      }
      .h3 {
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 1;
        max-width: 80%;
      }
    }
  }
  
  @media (max-width: ${breakpoints.xs}) {
    padding: 40px 0 0;
    
    .popup-content-wrapper {
        top: 2%;
        width: 350px;
    }
    
    &.wide .popup-content-wrapper {
      width: 350px; 
    }
    &.padded {
      padding: 50px 20px;
    }
  }
  
  @media (max-width: ${breakpoints.xxs}) {
    
    .popup-content-wrapper {
        width: 300px;
    }
    
    &.wide .popup-content-wrapper {
      width: 300px;
    }
  }
  
`;

const CloseButton = styled('button')`
  background: none;
  border: none;
  outline: none;
  color: #FFF;
  position: absolute;
  top: -30px;
  right: -30px;
  z-index: 2;
  opacity: 1;
  
  &:hover, &:active, &:focus {
    opacity: 0.7;
    cursor: pointer;
  }
  @media (max-width: ${breakpoints.xs}) {
    top: 0;
    right: 5px;
  }
`;





const closeHandler = (popupId) => {
	const layout = document.getElementById('all-content');
	if (layout) {
		layout.classList.remove('popup-blocked');
	}

	const popupWindow = document.getElementById(popupId);
	if (popupWindow) {
		popupWindow.classList.remove('popup-open');

		const iframe = document.getElementById('game-home-iframe');
		if (iframe) iframe.src = false;

	}
};

const invokePopup = (event, popupId) => {

	const GAME_IFRAME    = document.getElementById('game-home-iframe');
	const ALL_CONTENT_EL = document.getElementById('all-content');

	(ALL_CONTENT_EL)
	&& ALL_CONTENT_EL.addEventListener('click', (event) => {

		if ( ![...event.target.classList].includes('popup-content-wrapper')
			&& ![...event.target.classList].includes('h3')
			&& GAME_IFRAME
		) {
			GAME_IFRAME.src = '';
		}

	});


	event.preventDefault();
	const layout = document.getElementById('all-content');
	layout.classList.add('popup-blocked');
	const popupWindow = document.getElementById(popupId);
	popupWindow.classList.add('popup-open');
};

const invokePopupInitial = (popupId) => {
	const layout = document.getElementById('all-content');
	layout.classList.add('popup-blocked');
	const popupWindow = document.getElementById(popupId);
	popupWindow.classList.add('popup-open');
};

const closePopup = (event, popupId) => {
	closeHandler(popupId)
};

const PopupWindow = ( {children, popupId, title, extraClass} ) => {
	const classNames = extraClass ? extraClass + ' popup-window' : 'popup-window';
	return (
		<Popup id={popupId} className={classNames}>
			<div className="popup-content-wrapper">
				<CloseButton onClick={() => closeHandler(popupId)} className="close-button">
					<IcomoonIcon icon="gclub-close" color={color.white} size={26}/>
				</CloseButton>

				{title &&
				<div id={popupId + "-title"} className="h3" dangerouslySetInnerHTML={{ __html: title }}/>
				}
				<div id={popupId + '-content'} className="popup-content">
					{children}
				</div>
			</div>
			<div className="popup-overlay" onClick={() => closeHandler(popupId)}></div>
		</Popup>
	)
};

export default PopupWindow;
export {invokePopup, closePopup, invokePopupInitial};

PopupWindow.propTypes = {
	popupId: PropTypes.string,
	title: PropTypes.string,
	extraClass: PropTypes.string,
};


