import React, {useState, useEffect, useContext} from 'react';
import Coin from './Coin';
import {getAuthToken, getAuthHeader, Logout} from './Utils';
import {balanceUrl} from './Config';
import StoreContext from '../shared/Context/StoreContext';
import Refresh from '../../icons/svg/Refresh';

let setUsernameCallback = false;
const Balance = () =>{
  const [balance, setBalance] = useState('...');
  const [balanceLoading, setBalanceLoading] = useState(false);
  const StoreContextValue = useContext(StoreContext);
  setUsernameCallback = StoreContextValue.setUsernameCallback;

  const getPlayerBalance = async (setBalanceCallback, setUsernameCallback) => {
    if(!getAuthToken){
      return false;
    }
    setBalanceLoading(true)
    const response = await fetch(balanceUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthHeader()
      },
    });
    if (response.ok) {
      setBalanceLoading(false)
      let json = await response.json();
      if (json.error && json.message) {
        setBalanceCallback('N/A');
        if(json.error_type === 'session_expired'){
          console.log('Call Logout from balance');
          Logout(false, setUsernameCallback);
        }
      } else {
        let balance = json.balance;
        if('balance' in json) {
          if ('errors' in json && 'balance' in json.errors) {
            setBalanceCallback('N/A');
          } else {
            setBalanceCallback(balance);
          }
        }
        setBalanceLoading(false)
      }
    } else {
      setBalanceLoading(false)
      console.log("Balance Error HTTP: " + response.status);
      let json = await response.json();
      if(json.code === 'jwt_auth_invalid_token'){
        Logout(false, setUsernameCallback);
      }
    }
  };

  useEffect(() => {
    getPlayerBalance(setBalance, setUsernameCallback);
  }, []);

  const getBalance = () => {
    getPlayerBalance(setBalance, setUsernameCallback);
  }

  return (
  <div className="user-balance">
    <button className={`icon-wrap ${balanceLoading ? "update" : ""}`} disabled={balanceLoading} aria-label={"Update"} onClick={() => getBalance()}>
      <Refresh/>
    </button>
    <div className="coin-wrap">
      <Coin/>
    </div>
    <div className="coin-balance">{balance}</div>
  </div>
)};

export default Balance;