import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { color, formStyles, breakpoints } from '../../utils/style';
import { Formik, Field, Form } from 'formik';
import IcomoonIcon from '../shared/IcomoonIcon';
import withLocation from '../shared/withLocation';

const SearchWrap =  styled('div')`
   ${formStyles}
   .search-form {
     margin-top: 2px;
     max-width: 400px;
     margin: 0 auto;
     position: relative;
     input {
       border-color: ${color.white};
     }
     .error-new + input {
       border-color: ${color.red};
     }
     .fieldWrap {
       width: 100%;
       .error {
         right: auto;
         top: 1px;
         left: 50px;
         background: none;
         font-size: 11px;
       }
       label { display: none; }
     }
     .button {
       padding: 10px 12px;
       margin: 0 0 0 10px;
       position: absolute;
       top: 3px;
       right: 3px;
       background: transparent;
       border-color: transparent;
       &:hover, &:active {
         background: ${color.orange};
         svg {
          path {fill: ${color.trueBlack} !important;}
         }
       }
       &:disabled {
         background: transparent;
         border-color: transparent;
       }
     }
   }

   @media (max-width: ${breakpoints.md}) {
     padding: 15px;
     height: 75px;
     width: 100%;
     .search-form {
       width: calc(100% - 85px);
       float: left;
     }
   }
   @media (max-width: ${breakpoints.sm}) {
     .search-form {
       margin-top: 0;
       .fieldWrap {
         padding-bottom: 3px;
       }
     }
   }
`;


const FormBody = ({ search, mobileCloseFunction }) => {
  let query = search;
  let  searchtext = '';
  if (query && query.q) {
    searchtext = query.q;
  }
  const initialValues = {
    q: searchtext,
  };
  const onSubmit = (values, {resetForm}) => {
    const path = '/search/?q=' + values.q + '&subdomain='+process.env.SUBDOMAIN;
    navigate(path);
  };

  /*const onBlur = (e) => {
   const searchValue = e.target.value;
   if (query.q && (searchValue !== query.q)) {
     const path = '/search/?q=' + searchValue;
     mobileCloseFunction();
     navigate(path);
   }
  };*/

  const validateSearch = (values) => {
    let errors = {};
    let searchtext = values.q + '';
    if(searchtext.length < 3){
      errors.q = 'Minimal 3 huruf';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateSearch}
      render={({ errors, status, touched, isSubmitting, isValid, values }) => (
        <Form id="searchForm" className="search-form">
          <div className="fieldWrap">
            <label htmlFor="search-query">Cari</label>
            {errors.q && touched.q && <div className="error-new">! {errors.q}</div>}
            <Field type="text" name="q" placeholder="Cari" id="search-query"
                   value={values.q || ''} />
          </div>
          <button aria-label="Cari" className="button" type="submit" disabled={(isSubmitting || !isValid)}>
            <IcomoonIcon icon="search-2" color={color.white} size={18} />
          </button>

        </Form>
      )}
    />
  );
};

const SearchForm = ({ search, handlerFunction }) => {
  return(
    <SearchWrap>
      <FormBody search={search} mobileCloseFunction={handlerFunction}/>
    </SearchWrap>
  )
};

export default withLocation(SearchForm);

SearchForm.propTypes = {
  search: PropTypes.object,
  handlerFunction: PropTypes.func,
};